import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import moment from 'moment';

import PageLayout from '../components/pageLayout';
import SEO from '../components/seo';

import style from '../styles/post.module.css';

const pageName = 'About';

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      file: file(relativePath: { eq: "me.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const getMyCurrentAge = () => {
    const myDateOfBirh = moment('05-11-1993', 'MM-DD-YYYY');
    const currentDate = moment();

    return currentDate.diff(myDateOfBirh, 'years');
  };

  const myCurrentAge = React.useMemo(() => getMyCurrentAge(), []);

  return (
    <PageLayout>
      <SEO title={pageName} />
      <div className={style.post}>
        <div className={style.postContent}>
          <h1 className={style.title}>{pageName}</h1>
          <h2>Hi everyone</h2>
          <Img fluid={data.file.childImageSharp.fluid} className={style.coverImage} />
          <p>
            My name is{' '}
            <a href="https://github.com/Alex-Spike" rel="noreferrer" target="_blank">
              Alexey Nikiforov
            </a>{' '}
            and I&apos;m a {myCurrentAge}-year old front-end developer from Belarus, Minsk.
          </p>
          <p>
            I created this blog for sharing and structuring some things that I&apos;m interested in.
          </p>
          <p>Hope that you will also find something interesting and new here for yourself.</p>
        </div>
      </div>
    </PageLayout>
  );
};

export default About;
